import { render, staticRenderFns } from "./BecomeMem.vue?vue&type=template&id=00d5c548&scoped=true&"
import script from "./BecomeMem.vue?vue&type=script&lang=js&"
export * from "./BecomeMem.vue?vue&type=script&lang=js&"
import style0 from "./BecomeMem.vue?vue&type=style&index=0&id=00d5c548&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d5c548",
  null
  
)

export default component.exports